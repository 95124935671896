import React from "react";

const FocusedIcon = ({ className, height = 20, width = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_119_461)">
        <path
          d="M14.1667 3.33398H15C15.442 3.33398 15.8659 3.50958 16.1785 3.82214C16.4911 4.1347 16.6667 4.55862 16.6667 5.00065V5.83398"
          stroke="#3D3D3B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 14.166V14.9993C16.6667 15.4414 16.4911 15.8653 16.1785 16.1779C15.8659 16.4904 15.442 16.666 15 16.666H14.1667"
          stroke="#3D3D3B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83334 16.666H5C4.55798 16.666 4.13405 16.4904 3.82149 16.1779C3.50893 15.8653 3.33334 15.4414 3.33334 14.9993V14.166"
          stroke="#3D3D3B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33334 5.83398V5.00065C3.33334 4.55862 3.50893 4.1347 3.82149 3.82214C4.13405 3.50958 4.55798 3.33398 5 3.33398H5.83334"
          stroke="#3D3D3B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10Z"
          stroke="#3D3D3B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 15V16.6667"
          stroke="#3D3D3B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33334 10H5"
          stroke="#3D3D3B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 3.33398V5.00065"
          stroke="#3D3D3B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6667 10H15"
          stroke="#3D3D3B"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_119_461">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FocusedIcon;
