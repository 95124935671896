export const userSlice = (set, get) => ({
  subscriptions: {
    planName: "",
    planId: null,
    isActive: false,
    isCancelled: false,
    renewDate: null,
    endDate: null,
  },
  aiGenerations: 0,
  totalAiGenerations: 0,
  storage: 0,
  totalStorage: 0,
  dataQueried: 0,
  totalDataQueried: 0,
  executionTime: 0,
  setUserBillingInit: (val) => {
    set({
      subscriptions: {
        planName: val.subscriptions.planName,
        planId: val.subscriptions.planId,
        isActive: val.subscriptions.isActive,
        isCancelled: val.subscriptions.isCancelled,
        renewDate: val.subscriptions.renewDate,
        endDate: val.subscriptions.endDate,
      },
      aiGenerations: val.aiGenerations,
      totalAiGenerations: val.totalAiGenerations,
      storage: val.storage,
      totalStorage: val.totalStorage,
      dataQueried: val.dataQueried,
      totalDataQueried: val.totalDataQueried,
      executionTime: val.executionTime,
    });
  },
  setUserBillingField: (name, value) => {
    set({ [name]: value });
  },
});
