import React from "react";

const CasualIcon = ({ className, height = 20, width = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_121_552)">
        <path
          d="M3.16666 3.95898H4.75"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.95834 3.16602V4.74935"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.10416 3.16602L8.70833 4.74935"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 3.95898H15.8333"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0417 3.16602V4.74935"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.875 7.125L11.0833 7.91667"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 10.2923L15.8333 9.89648"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 15.041H15.8333"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0417 14.25V15.8333"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0833 13.0761L5.92325 7.91602L2.44784 15.5002C2.37914 15.6474 2.35743 15.8123 2.38563 15.9723C2.41383 16.1323 2.49059 16.2798 2.60549 16.3947C2.72038 16.5096 2.86785 16.5863 3.02787 16.6145C3.18789 16.6427 3.35271 16.621 3.49996 16.5523L11.0833 13.0761Z"
          stroke="#3D3D3B"
          strokeWidth="0.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_121_552">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CasualIcon;
