import { getAllDataSources } from "@/axios";

import { noop } from "@/components/utility";
import toast from "react-hot-toast";

export const dataSourceSlice = (set, get) => ({
  sources: [],
  dataSourceLoading: true,
  syncedDbOptions: [],
  setSyncedDbOptions: (options) => set({ syncedDbOptions: options }),

  loadingDbOptions: true,
  setLoadingDbOptions: (val) => set({ loadingDbOptions: val }),

  fetchSources: async () => {
    set({ dataSourceLoading: true });
    try {
      const res = await getAllDataSources();
      const { data: DATA } = res.data;
      const formatedCardsData = (DATA) => {
        const obj = DATA;
        const objArray = [];
        for (const key of Object.keys(obj)) {
          const uid = key;
          const otherProps = { ...obj[key] };
          objArray.push({ uid, ...otherProps });
        }
        return objArray;
      };
      const data = formatedCardsData(DATA);
      data.sort((a, b) => new Date(b.uploaded_at) - new Date(a.uploaded_at));

      const checkSyncObj = data.filter((item) => item.check_status); // checking if any object check_status is true if rather we start checking
      if (checkSyncObj.length) {
        get().cancelControllers.forEach((abort) => abort.abort()); // abort any stream before
        get().cleanUpCancelControllers(); // clean up cancel abort controllers
        get().setSyncActiveObj(checkSyncObj);
        get().setShouldSSEFetch(true);
      }
      const pollingObjs = data.filter(
        (item) =>
          item.check_trained_model_status ||
          (!item.check_trained_model_status &&
            item.training_status === "training")
      );
      pollingObjs.forEach((item) => {
        get().setPollingListItem(item.uid, {
          currentStatus: "training",
          timerId: "",
          pollFunc: () => get().poll({ id: item.uid }),
          isCalled: false,
        });
      });
      get().setShouldStartPolling(true);

      for (const key in get().pollingList) {
        const { pollFunc = noop, isCalled } = get().pollingList[key];
        if (!isCalled) {
          pollFunc();
          get().updatePollingListItem(key, { isCalled: true });
        }
      }
      set({ sources: data });
    } catch (error) {
      const { response } = error;
      const { status, data } = response || {};
      if (status) {
        toast.error(data?.detail);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      set({ dataSourceLoading: false });
    }
  },
});
