import React from "react";

const SonnetIcon = ({
  className,
  height = 20,
  width = 20,
  fill = "#3D3D3B",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_121_603)">
        <path
          d="M12.2614 0H9.66161L14.4002 12H17L12.2614 0ZM4.73861 0L0 12H2.6551L3.63232 9.48923H8.59219L9.55098 12H12.2061L7.46746 0H4.73861ZM4.48048 7.25538L6.10304 3.04615L7.7256 7.25538H4.48048Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_121_603">
          <rect width="17" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SonnetIcon;
