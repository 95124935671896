import { getTrainingStatus } from "@/axios";
import { noop } from "@/components/utility";
import { getToken } from "@/storage";
export const WORDS = {
  "sync started": "sync started",
  syncing: "syncing...",
  "sync completed": "synced",
  "sync failed": "failed",
};
export const STATUS = {
  completed: "completed",
  "sync failed": "failed",
  started: "started",
};
export const sseSlice = (set, get) => ({
  syncActiveObjs: [],
  shouldSSEFetch: true,
  cancelControllers: [],
  setCancelControllers: (val) =>
    set({ cancelControllers: [...get().cancelControllers, val] }),
  cleanUpCancelControllers: () => set({ cancelControllers: [] }),
  setShouldSSEFetch: (val) => set({ shouldSSEFetch: val }),
  setSyncActiveObj: (activeObjs = []) => {
    const obj = activeObjs.map((item) => {
      const { uid, source_name, source_type, current_status } = item;
      return {
        name: source_name,
        uid: uid,
        type: source_type,
        status: current_status,
      };
    });
    set({
      syncActiveObjs: obj,
    });
  },
  updateSyncActiveObj: (uuid, data) => {
    const obj = [...get().syncActiveObjs];
    const itemIndx = obj.findIndex((active) => active.uid === uuid);
    obj[itemIndx] = { ...obj[itemIndx], ...data };
    set({ syncActiveObjs: obj });
  },
  removeSyncActiveObj: (uuid) => {
    const obj = [...get().syncActiveObjs].filter((item) => item.uid !== uuid);
    set({
      syncActiveObjs: obj,
    });
    if (obj.length === 0) get().setShouldSSEFetch(true);
  },
  sseFetch: async (url, configs) => {
    try {
      get().setShouldSSEFetch(false);
      const { callback, data, signal } = configs ?? {};
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
        signal,
      });
      /* eslint-disable */
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      while (true) {
        const obj = await reader.read();
        const { uuid } = data;
        let updateString = "",
          statusString = "";

        if (obj.done) {
          let current_status = null;
          const res = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({
              latest_status: true,
              ...data,
            }),
          });
          const { current_sync_status } = (await res.json()).data;
          current_status = current_sync_status;

          if (
            current_status === STATUS.completed ||
            current_status === STATUS["sync failed"]
          ) {
            callback(
              Object.assign(obj, {
                isSuccess: current_status === STATUS.completed,
                status: current_status,
              })
            );
            get().updateSyncActiveObj(uuid, {
              value: "",
              status: current_status,
            });
            setTimeout(() => {
              get().removeSyncActiveObj(uuid);
            }, 1000);
          } else {
            setTimeout(() => get().sseFetch(url, configs), 2000);
          }
          break;
        }

        if (obj.value.includes(Object.keys(WORDS)[0])) {
          updateString = WORDS["sync started"];
          statusString = STATUS.started;
        }
        if (obj.value.includes(Object.keys(WORDS)[1])) {
          updateString = WORDS.syncing;
          statusString = STATUS.started;
        }
        if (obj.value.includes(Object.keys(WORDS)[2])) {
          updateString = WORDS["sync completed"];
          statusString = STATUS.completed;
        }
        if (obj.value.includes(Object.keys(WORDS)[3])) {
          updateString = WORDS["sync failed"];
          statusString = STATUS["sync failed"];
        }

        const current = get().syncActiveObjs.find((item) => item.uid === uuid);
        if (current.value !== updateString || current.status !== statusString) {
          get().updateSyncActiveObj(uuid, {
            value: updateString,
            status: statusString,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
});

// This is how data structure is for future reference
// pollList = {
//   uuid : {
//     currentStatus : "training",
//     timerId : "dkamkdma",
//     isCalled : false,
//     pollFunc : ()=>{
//     }
//   }
// }

export const pollingSlice = (set, get) => ({
  pollingList: {},
  shouldStartPolling: false,
  setShouldStartPolling: (val) => set({ shouldStartPolling: val }),
  setPollingListItem: (id, obj = {}) => {
    if (get().pollingList[id] === undefined) {
      set({ pollingList: { ...get().pollingList, [id]: obj } });
    }
  },
  updatePollingListItem: (id, obj = {}) => {
    const list = get().pollingList;
    list[id] = {
      ...list[id],
      ...obj,
    };

    set({ pollingList: { ...list } });
  },
  removeFromPollingList: (id) => {
    const obj = get().pollingList;
    delete obj[id];
    set({ pollingList: { ...obj } });
  },
  poll: async ({ id }) => {
    getTrainingStatus(id)
      .then((res) => {
        if (res.status === 200) {
          get().updatePollingListItem(id, {
            currentStatus: res.data.data.status,
          });
          clearTimeout(get().pollingList[id].timerId);

          setTimeout(() => get().removeFromPollingList(id), 1000);
        } else if (res.status === 202) {
          let timer = setTimeout(() => get().poll({ id }), 5000);

          get().updatePollingListItem(id, {
            timerId: timer,
            currentStatus: "training",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // if (error.status === 404) {
        get().updatePollingListItem(id, { currentStatus: "failed" });
        clearTimeout(get().pollingList[id].timerId);

        setTimeout(() => get().removeFromPollingList(id), 1000);
        // }
      });
  },
});
