import { userSlice } from "./userSlice";
import { sseSlice, pollingSlice } from "./statusServicesSlice";
import { dataSourceSlice } from "./dataSourceSlice";
import { chatSlice, editorSlice } from "./querySlice";
import { notificationSlice } from "./notificationSlice";
import { historySlice } from "./historySlice";
import { recentSlice } from "./recentSlice";
import { modeSlice } from "./modeSlice";
const slices = {
  userSlice,
  sseSlice,
  pollingSlice,
  dataSourceSlice,
  chatSlice,
  editorSlice,
  notificationSlice,
  historySlice,
  recentSlice,
  modeSlice,
};

export default slices;
