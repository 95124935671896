import axios from "axios";
import { getToken, getUser, removeToken, removeUser } from "./storage";
import Router from "next/router";
import { routesURL } from "./components/urls";
import { Cache } from "./components/utility/cache";
import posthog from "posthog-js";
import { googleLogout } from "@react-oauth/google";

export const isProd = true;
const axiosCache = new Cache();

const authUrls = [routesURL.login, routesURL.signup];

const STAGING = "https://staged.flamingos.dev/";
const PROD = "https://api.flamingos.dev/";
const DEV = "http://127.0.0.1:8000/";

const STAGING_USER = "https://staging-shepherd.mohdtausif.workers.dev/";
const USER_PROD = "https://data.flamingos.dev";
const USER_DEV = "http://127.0.0.1:8787";

export const STAGING_AI = "https://staging-ragdoll.mohdtausif.workers.dev/";
export const AI_PROD = "https://ai.flamingos.dev/";
export const AI_DEV = "http://localhost:8787/";
export const STAGING_CHAT =
  "https://tausif3--staging-lydia-chat-chat.modal.run/";
export const PROD_EXPLANATION =
  "https://tausif3--lydia-sql-explanations-chat.modal.run/";
export const STAGING_EXPLANATION =
  "https://tausif3--staging-lydia-sql-explanations-chat.modal.run/";

export const instance = axios.create({
  baseURL: isProd ? PROD : STAGING,
});

export const userInstance = axios.create({
  baseURL: isProd ? USER_PROD : STAGING_USER,
});

export const AIinstance = axios.create({
  baseURL: AI_DEV,
});

AIinstance.interceptors.request.use(function (config) {
  config.headers["X-EMAIL"] = getUser();
  return config;
});

userInstance.interceptors.request.use(function (config) {
  config.headers["X-EMAIL"] = getUser();
  return config;
});
userInstance.interceptors.request.use(
  function (config) {
    config.metadata = { startTime: new Date() };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
userInstance.interceptors.response.use(
  function (response) {
    response.config.metadata.endTime = new Date();
    response.config.metadata.duration =
      response.config.metadata.endTime - response.config.metadata.startTime;
    return response;
  },
  function (error) {
    error.config.metadata.endTime = new Date();
    error.config.metadata.duration =
      error.config.metadata.endTime - error.config.metadata.startTime;
    return Promise.reject(error);
  }
);
const instanceCaching = (url, callback, cacheDuration = 1000 * 60) =>
  axiosCache.cache({
    axiosInstance: instance,
    url: url,
    method: "GET",
    callback: callback,
    cacheDuration: cacheDuration,
  });

instance.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let err = error;
    if (error?.response?.status === 401) {
      if (!authUrls.includes(Router?.pathname)) {
        googleLogout();
        removeToken();
        removeUser();
        posthog.reset();
        Router.reload();
        err = {
          ...err,
          response: {
            ...err.response,
            data: { detail: "Session expired. Redirecting to login." },
          },
        };
      }
    }
    return Promise.reject(err);
  }
);

export const login = (data) => instance.post("/login", data);
export const signUp = (data) => instance.post("/signup", data);
export const joinWaitList = (data) => instance.post("/join-waitlist", data);
export const googleVerify = (email) =>
  instance.get("/verify-email?oauth_email=" + email);

export const newDataSource = (data) =>
  userInstance.post("/datasources/new", data);
export const fileUpload = (data, config = {}) =>
  userInstance.post("/datasources/upload", data, config);

export const getAllDataSources = () => instance.get("/datasources");
// export const getDataSource = (id) => instance.get("/datasources/source/" + id);
export const deleteDataSource = (id) =>
  userInstance.delete(`/datasources/source/${id}`);
export const getSyncedDataSources = (id) =>
  instance.get("/datasources/synced", { params: id ? { id } : "" });
export const getTrainingStatus = (id) =>
  instance.get("/training-status?data_source_uuid=" + id);

export const postSql = (data, config = {}) =>
  AIinstance.post("/text-to-sql", data, config);
export const postQueries = (data, config = {}) =>
  userInstance.post("/query", data, config);

export const getSourceLogs = (uid, callback) =>
  instanceCaching("datasources/logs/" + uid, callback);
export const cancelValidate = (uid) =>
  axiosCache.cancelRevalidate("datasources/logs/" + uid);

// editor mode logs
export const getQueryLogs = () => instance.get("/query");
export const getQueryLogById = (id, config = {}) =>
  instance.get("/query/" + id, config);
export const updateQueryLogById = (id, data) =>
  instance.put("/query/" + id, data);
export const deleteQueryLog = (id) => instance.delete("/query/" + id);
// chat mode logs
export const getChatLogs = () => instance.get("/chat");
export const getChatLogById = (id, config = {}) =>
  instance.get("/chat/" + id, config);
export const updateChatLogById = (id, data) =>
  instance.put("/chat/" + id, data);
export const deleteChatLog = (id) => instance.delete("/chat/" + id);

// get sample questions
export const getSampleQuestions = (uid, config) =>
  userInstance.get("/questions?data_source_uuid=" + uid, config);

export const usageBilling = (config) =>
  axios.get((isProd ? PROD : STAGING) + "user/account/usage", config);
export const planDetails = (config) =>
  axios.get((isProd ? PROD : STAGING) + "user/account/plan-details", config);

export const planCancel = (config) =>
  axios.delete((isProd ? PROD : STAGING) + "user/account/plan", config);

export const feedback = (data) => instance.post("/user/feedback", data);
export const blogs = () => instance.get("/blog");
export const blogByTitle = (title) => instance.get("/blog/" + title);

export const setUserPreferences = (data) =>
  instance.post("user/preferences", data);
export const getUserCurrentPreferences = () => instance.get("user/preferences");
// cancel req callback
export const cancelSingal = () => {
  const controller = new AbortController();
  return {
    signal: controller.signal,
    abort: controller.abort.bind(controller),
  };
};
