export const routesURL = {
  index: "/",
  about: "/about",
  privacyPolicy: "/privacy-policy",
  verifyEmail: "/verify-email",
  dasboard: "/dashboard",
  login: "/login",
  signup: "/sign-up",
  datasource: "/datasource",
  newDatasource: "/datasource/new",
  chat: "/chat",
  query: "/notebook",
  guides: "/guides",
  startGuide: "/guides/start-guide",
  dataSourceGuide: "/guides/datasource-guide",
  dashboardQuery: {
    dynamic: (id) => "/notebook/" + id,
    asPath: "/notebook/[uuid]",
  },
  dashboardChat: {
    dynamic: (id) => "/chat/" + id,
    asPath: "/chat/[uuid]",
  },
  plans: "/plans",
  pricing: "/pricing",
  usageBilling: "/usage-billing",
  blog: "/blog",
  blogArticle: {
    dynamic: (slug) => "/blog/" + slug,
    asPath: "/blog/[article]",
  },
};
