export const noop = () => {};

export const delay = (time) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, time);
  });
};

export const uuid = function () {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export function extractNameFromEmail(email) {
  const parts = email?.split("@");
  if (parts?.length === 2) {
    const nameParts = parts[0]?.split(".");
    const name = nameParts
      .map((part) => part?.charAt(0) + part?.slice(1))
      .join(" ");
    return name;
  }

  return "";
}

export const isInt = (number) => number % 1 === 0;

export const formatNumber = (number) => {
  try {
    if (isNaN(number)) return number;
    if (number > 1000000000) {
      let num = number / 1000000000;
      num = isInt(num) ? num : num.toFixed(2);
      return num.toString() + "B";
    } else if (number > 1000000) {
      let num = number / 1000000;
      num = isInt(num) ? num : num.toFixed(2);
      return num.toString() + "M";
    } else if (number > 1000) {
      let num = number / 1000;
      num = isInt(num) ? num : num.toFixed(2);
      return num.toString() + "K";
    } else {
      let num = number;
      num = isInt(num) ? num : num.toFixed(2);
      return num.toString();
    }
  } catch (error) {
    return number;
  }
};
export const formatNumber2 = (number) => number.toLocaleString("en-US");

export function sanitizeColumnName(name) {
  return name.replace(/[^a-zA-Z0-9_$]/g, "_");
}

export const compareValues = (valueA, valueB, reverse = false) => {
  const aValue = valueA === null ? "" : valueA;
  const bValue = valueB === null ? "" : valueB;

  if (typeof aValue === "number" && typeof bValue === "number") {
    return reverse ? bValue - aValue : aValue - bValue; // Numeric sorting
  } else {
    const aString = aValue.toString();
    const bString = bValue.toString();

    return reverse
      ? bString.localeCompare(aString)
      : aString.localeCompare(bString); // String sorting
  }
};

export function isExpired(timestamp) {
  const inputTimestamp = new Date(timestamp).getTime();
  const currentTimestamp = Date.now();

  const timeDifference = currentTimestamp - inputTimestamp;
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  return timeDifference > sevenDaysInMilliseconds;
}
export function formatTimeAgo(timestamp) {
  const now = new Date().getTime();
  const diff = now - timestamp;

  // Calculate time differences in milliseconds, seconds, minutes, hours, and days
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30; // Assuming a month is 30 days

  if (diff < minute) {
    return "just now";
  } else if (diff < 2 * minute) {
    return "a minute ago";
  } else if (diff < hour) {
    return Math.floor(diff / minute) + " minutes ago";
  } else if (diff < 2 * hour) {
    return "an hour ago";
  } else if (diff < day) {
    return Math.floor(diff / hour) + " hours ago";
  } else if (diff < 2 * day) {
    return "yesterday";
  } else if (diff < week) {
    return Math.floor(diff / day) + " days ago";
  } else if (diff < 2 * week) {
    return "a week ago";
  } else if (diff < month) {
    return Math.floor(diff / week) + " weeks ago";
  } else if (diff < 2 * month) {
    return "a month ago";
  } else {
    return Math.floor(diff / month) + " months ago";
  }
}

export function bytesToMB(bytes, showUnit = false, fixedPoint = 2) {
  return `${(bytes / 1e6).toFixed(fixedPoint)}${showUnit ? " MB" : ""}`;
}

export function millisecondsToTime(milliseconds) {
  const minutes = (milliseconds / (1000 * 60)) % 60;
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));

  if (hours >= 1) {
    return hours.toFixed(0) + " hours";
  } else {
    return minutes.toFixed(2) + " minutes";
  }
}
// Output: '2 hours' '1minute'
export function millisecondsToDate2(milliseconds) {
  const date = new Date(milliseconds);
  // const currentYear = new Date().getFullYear();
  // const dateYear = date.getFullYear();

  const options = { day: "2-digit", month: "long", year: "numeric" };

  return date.toLocaleDateString("en-GB", options); // Adjust locale as needed
}

// Output: "22 Jan 2023"

export const calculateDaysDifference = (endDateStr) => {
  const endDate = new Date(endDateStr);
  const currentDate = new Date();

  const timeDifference = endDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
};

export const isDeepEqual = (object1, object2) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const isObject = (object) => {
  return object != null && typeof object === "object";
};

export const modifyObject = (obj, path, value) => {
  if (!obj || typeof obj !== "object") return obj;

  if (typeof path === "string") {
    path = path
      .split(".")
      .map((key) => (isNaN(Number(key)) ? key : Number(key)));
  }

  let current = obj;

  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    if (i === path.length - 1) {
      current[key] = value;
    } else {
      if (!current[key] || typeof current[key] !== "object") {
        current[key] = typeof path[i + 1] === "number" ? [] : {};
      }
      current = current[key];
    }
  }

  return obj;
};
