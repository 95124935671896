import { isExpired, uuid } from "@/components/utility";
import { getUser } from "@/storage";

export const notificationSlice = (set, get) => ({
  notifications: [],
  getNotifications: () => {
    const olds = JSON.parse(localStorage.getItem(getUser())) || [];
    const active = olds.filter((item) => !isExpired(item.time));
    set({ notifications: active });
    if (olds.length !== active.length) {
      localStorage.setItem(getUser(), JSON.stringify([...active]));
    }
  },
  pushToNotification: (notification, status) => {
    let newNotifcation = [];
    const prevNotification = get().notifications;
    const notificationObj = {
      content: notification,
      isViewed: false,
      id: uuid(),
      status: status,
      time: Date.now(),
    };
    if (prevNotification) {
      newNotifcation = [notificationObj, ...prevNotification];
    } else {
      newNotifcation = [notificationObj];
    }
    localStorage.setItem(getUser(), JSON.stringify(newNotifcation));
    get().getNotifications();
  },
  updateViewNotification: (id) => {
    const old = [...get().notifications];
    const indx = old.findIndex((it) => it.id === id);
    if (indx !== -1 && old[indx].isViewed) return;
    if (indx !== -1) {
      old[indx] = { ...old[indx], isViewed: true };
    }
    localStorage.setItem(getUser(), JSON.stringify([...old]));
    get().getNotifications();
  },
  deleteNotifcation: (id) => {
    const oldNotif = [...get().notifications];
    const newNotif = oldNotif.filter((it) => it.id !== id);
    localStorage.setItem(getUser(), JSON.stringify([...newNotif]));
    get().getNotifications();
  },
});
