import { useAppStore } from "@/components/lib/store";
import { useShallow } from "zustand/react/shallow";

export const PLANS_ID = {
  1: "Free",
  2: "Starter",
  3: "Team",
  4: "Growth",
  5: "EnterPrise",
};
export const PLANS_NAME = {
  Free: 1,
  Starter: 2,
  Team: 3,
  Growth: 4,
  EnterPrise: 5,
};
const usePayments = () => {
  const {
    subscriptions,
    aiGenerations,
    totalAiGenerations,
    storage,
    totalStorage,
    dataQueried,
    totalDataQueried,
    setUserBillingInit,
    executionTime,
    setUserBillingField,
  } = useAppStore(
    useShallow((state) => ({
      subscriptions: state.subscriptions,
      aiGenerations: state.aiGenerations,
      totalAiGenerations: state.totalAiGenerations,
      storage: state.storage,
      totalStorage: state.totalStorage,
      dataQueried: state.dataQueried,
      totalDataQueried: state.totalDataQueried,
      setUserBillingInit: state.setUserBillingInit,
      executionTime: state.executionTime,
      setUserBillingField: state.setUserBillingField,
    }))
  );

  const usersBillingInit = (usersBilling) => {
    const { usage, plan } = usersBilling;
    setUserBillingInit({
      subscriptions: {
        planName: plan.name,
        planId: plan.plan_id,
        isActive: plan.subscription_status === "active",
        isCancelled: plan.name !== PLANS_ID[1] && Boolean(plan.ends_at),
        renewDate: plan.renews_at,
        endDate: plan.ends_at,
      },
      aiGenerations: usage[5].total_ai_generations,
      totalAiGenerations: plan.quotas.ai_generations,
      storage: usage[3].total_storage_used,
      totalStorage: plan.quotas.storage,
      dataQueried: usage[4].total_data_queried,
      totalDataQueried: plan.quotas.queried_data,
      executionTime: usage[6].total_query_execution_time,
    });
  };
  const isAiLimit = aiGenerations / totalAiGenerations >= 1;
  const isStorageLimit = storage / totalStorage >= 1;
  const isQueryLimit = dataQueried / totalDataQueried >= 1;

  const isFreePlan = subscriptions.planId === PLANS_NAME.Free;
  return {
    isFreePlan,
    isAiLimit,
    isStorageLimit,
    isQueryLimit,
    subscriptions,
    aiGenerations,
    totalAiGenerations,
    storage,
    totalStorage,
    dataQueried,
    totalDataQueried,
    usersBillingInit,
    setUserBillingField,
    executionTime,
  };
};

export default usePayments;
