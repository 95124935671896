import "@/styles/globals.css";
import "@/styles/global.scss";
import { Toaster } from "react-hot-toast";
import GlobalWrapper from "@/components/contexts/global-wrapper/GlobalWrapper";
import { useRouter } from "next/router";
import { routesURL } from "@/components/urls";
import NextNProgress from "nextjs-progressbar";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleAnalytics } from "@next/third-parties/google";
import dynamic from "next/dynamic";

const Layout = dynamic(() => import("@/components/layout/index"));
if (typeof window !== "undefined") {
  // checks that we are client-side
  posthog.init(
    process.env.NODE_ENV === "development"
      ? process.env.NEXT_PUBLIC_POSTHOG_KEY_DEV
      : process.env.NEXT_PUBLIC_POSTHOG_KEY_PROD,
    {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.debug(false); // debug mode in development
      },
    }
  );
}

const sidebarConfig = {
  [routesURL.index]: {
    showSidebar: false,
    hideNavbar: true,
    showHeader: false,
  },
  [routesURL.about]: {
    showSidebar: false,
    hideNavbar: true,
    showHeader: false,
  },
  [routesURL.privacyPolicy]: {
    showSidebar: false,
    hideNavbar: true,
    showHeader: false,
  },
  [routesURL.verifyEmail]: {
    showSidebar: false,
    hideNavbar: true,
    showHeader: false,
  },
  [routesURL.dasboard]: {
    showSidebar: true,
    showCurrentDb: false,
    divider: false,
  },
  [routesURL.chat]: {
    showSidebar: true,
    showCurrentDb: true,
    divider: false,
  },
  [routesURL.query]: {
    showSidebar: true,
    showCurrentDb: true,
    divider: false,
  },
  [routesURL.dashboardQuery.asPath]: {
    showSidebar: true,
    showCurrentDb: true,
    divider: false,
  },
  [routesURL.dashboardChat.asPath]: {
    showSidebar: true,
    showCurrentDb: true,
    divider: false,
  },
  [routesURL.plans]: {
    showSidebar: true,
    divider: false,
    showHeader: true,
  },
  [routesURL.pricing]: {
    showSidebar: false,
    hideNavbar: true,
    showHeader: false,
  },
  [routesURL.usageBilling]: {
    showSidebar: true,
    divider: false,
    showHeader: true,
  },
  [routesURL.datasource]: {
    showSidebar: true,
    divider: false,
    showHeader: true,
  },
  [routesURL.newDatasource]: {
    showSidebar: true,
    divider: false,
    showHeader: true,
  },
  [routesURL.login]: {
    showHeader: false,
    hideNavbar: true,
  },
  [routesURL.signup]: {
    showHeader: false,
    hideNavbar: true,
  },
  [routesURL.blog]: {
    showSidebar: false,
    hideNavbar: true,
    showHeader: false,
  },
  [routesURL.blogArticle.asPath]: {
    showSidebar: false,
    hideNavbar: true,
    showHeader: false,
  },
};
export default function App({
  Component,
  pageProps: { session, ...pageProps },
}) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <>
      <GoogleOAuthProvider
        clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}
      >
        <PostHogProvider client={posthog}>
          <GlobalWrapper {...pageProps}>
            <NextNProgress
              color="#9C9C9C"
              startPosition={0.7}
              options={{ showSpinner: false, easing: "ease", speed: 800 }}
            />
            <Layout pageProps={pageProps} {...sidebarConfig[router.pathname]}>
              <Component {...pageProps} />
            </Layout>
            <Toaster
              position={"top-center"}
              reverseOrder={false}
              gutter={8}
              toastOptions={{ duration: 5000 }}
            />
          </GlobalWrapper>
        </PostHogProvider>
      </GoogleOAuthProvider>
      <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GA_ID} />
    </>
  );
}
