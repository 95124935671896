import { noop } from ".";

export class Cache {
  static instance = null;
  #cache;
  #revalidationTimers;
  #updateCallbacks;
  constructor() {
    if (!Cache.instance) {
      this.#cache = {};
      this.#revalidationTimers = {};
      this.#updateCallbacks = {};
      Cache.instance = this;
    } else {
      return Cache.instance;
    }
  }

  getAllCache() {
    return this.#cache;
  }

  getAllTimers() {
    return this.#revalidationTimers;
  }

  getAllCallback() {
    return this.#updateCallbacks;
  }

  getCache(url) {
    return this.#cache[url];
  }
  // eslint-disable-next-line
  #setCache(url, response) {
    this.#cache = Object.assign(this.#cache, { [url]: response });
  }

  // eslint-disable-next-line
  #setUpdateCallback(url, id, callback) {
    this.#updateCallbacks[url] = {
      ...this.#updateCallbacks[url],
      [id]: callback,
    };
  }
  // eslint-disable-next-line
  #startRevalidation(
    url,
    id,
    cacheDuration,
    { axiosInstance, URL, method, headerProps, data }
  ) {
    if (this.#revalidationTimers[url]) {
      clearInterval(this.#revalidationTimers[url]);
    }
    this.#revalidationTimers[url] = setInterval(() => {
      this.#axiosCall({
        axiosInstance,
        URL,
        method,
        headerProps,
        data,
        url,
        id,
      });
    }, cacheDuration);
  }

  cache({
    axiosInstance,
    url,
    method = "GET",
    headerProps,
    data,
    revalidate = true,
    cacheDuration = 1000 * 60,
    callback = noop,
    id,
  }) {
    const URL = axiosInstance.defaults.baseURL + url;
    this.#setUpdateCallback(url, id, callback);

    const getCache = this.getCache(URL);
    const isStale = getCache
      ? cacheDuration <= new Date().getTime() - getCache.cached_at
      : true;

    if (!isStale) return this.#updateCallbacks[url][id](getCache.response);
    else {
      if (revalidate) {
        this.#startRevalidation(url, cacheDuration, {
          axiosInstance,
          URL,
          headerProps,
          data,
          method,
        });
      }
      this.#axiosCall({
        axiosInstance,
        URL,
        method,
        headerProps,
        data,
        url,
        id,
      });
    }
  }
  // eslint-disable-next-line
  #axiosCall({ axiosInstance, URL, method, headerProps, data, url, id }) {
    return axiosInstance({
      url: URL,
      method: method,
      headers: { ...axiosInstance.defaults.headers, ...headerProps },
      params: data,
    })
      .then((res) => {
        this.#setCache(URL, {
          response: res,
          cached_at: new Date().getTime(),
        });
        updateCb(this.#updateCallbacks[url], res);
        return this.#updateCallbacks[url][id](res);
      })
      .catch((err) => err);
  }

  cancelRevalidate(url) {
    clearInterval(this.#revalidationTimers[url]);
  }
}
function updateCb(obj, res) {
  for (const key in obj) {
    obj[key] = res;
  }
}
