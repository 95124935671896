import React, { createContext, useContext, useEffect, useRef } from "react";
import { useAppStore } from "../../lib/store";
import useToast from "../../shared/snackBar/useToast";
import { getSyncStatusURl } from "@/urls";
import { useShallow } from "zustand/react/shallow";
import { noop } from "../../utility";
import { useRouter } from "next/router";
import useLocalStorage from "@/components/hooks/useLocalStorage";
import useDbSnapshot from "@/components/hooks/data-fetching/useDbSnapshot";
import usePayments from "@/components/hooks/data-fetching/usePayments";
import { STATUS } from "@/components/lib/slices/statusServicesSlice";

const GlobalContext = createContext({
  imperativeRef: null,
  callBacks: { SSE: [] },
  setToggleSidebar: noop,
  toggleSidebar: true,
});
const GlobalWrapper = ({ children, ...pageProps }) => {
  const router = useRouter();
  const [toggleSidebar, setToggleSidebar] = useLocalStorage(
    "sidebar_view",
    true
  );
  const {
    syncActiveObjs,
    sseFetch,
    fetchSources,
    shouldSSEFetch,
    setCancelControllers,
    getNotifications,
    pushToNotification,
  } = useAppStore(
    useShallow((state) => ({
      syncActiveObjs: state.syncActiveObjs,
      sseFetch: state.sseFetch,
      fetchSources: state.fetchSources,
      shouldSSEFetch: state.shouldSSEFetch,
      setCancelControllers: state.setCancelControllers,
      getNotifications: state.getNotifications,
      pushToNotification: state.pushToNotification,
    }))
  );
  const { usersBillingInit } = usePayments();
  const { fetchSyncedDb } = useDbSnapshot();
  const { enqueueSnackbar } = useToast();

  const imperativeRef = useRef({});
  const runSSECallbacks = (obj) => {
    // this call is for other functions to be called after SSE has been done
    fetchSyncedDb(false);
  };
  const syncCallback = (name) => (obj) => {
    if (obj.done) {
      if (
        obj.status === STATUS.completed ||
        obj.status === STATUS["sync failed"] ||
        obj.status === ""
      ) {
        runSSECallbacks(obj);
        obj.isSuccess
          ? enqueueSnackbar(`${name} successfully synced.`)
          : enqueueSnackbar(`${name} sync failed.`, "error");
        obj.isSuccess
          ? pushToNotification(`${name} successfully synced.`, obj.isSuccess)
          : pushToNotification(`${name} sync failed.`, obj.isSuccess);
      }
    }
  };

  function init() {
    const { user, usersBilling } = pageProps;
    if (user) {
      router.query.shouldFetchServerSide !== "NO" &&
        usersBillingInit(usersBilling);
      fetchSources();
      getNotifications();
      fetchSyncedDb(false);
    }
  }

  useEffect(() => {
    init();
  }, [router.asPath]);

  useEffect(() => {
    if (syncActiveObjs.length && shouldSSEFetch) {
      syncActiveObjs.forEach((actObj) => {
        const abort = new AbortController();
        setCancelControllers(abort);
        const { name, uid, type } = actObj;
        sseFetch(getSyncStatusURl(uid, type), {
          callback: syncCallback(name),
          data: { uuid: uid },
          signal: abort.signal,
        });
      });
    }
  }, [syncActiveObjs, shouldSSEFetch]);
  return (
    <GlobalContext.Provider
      value={{ imperativeRef, toggleSidebar, setToggleSidebar }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export const useGlobalContext = () => useContext(GlobalContext);
export default GlobalWrapper;
