import { useEffect, useState } from "react";
import t, { useToasterStore } from "react-hot-toast";
export const toastsBar = (message, type, config = {}) => {
  switch (type) {
    case "success": {
      t.success(<span className=" break-all"> {message}</span>, {
        ...config,
      });
      break;
    }
    case "error": {
      t.error(<span className=" break-all">{message}</span>, { ...config });
      break;
    }
    case "custom": {
      t.custom(<message />, { ...config });
      break;
    }
    case "loading": {
      t.loading(<span className=" break-all"> {message}</span>, {
        ...config,
      });
      break;
    }
    case "promise": {
      if (typeof message !== "object")
        throw new Error("Expected message as object");
      const { fetchFunc, loadingText, errorText, successText } = message;
      t.promise(fetchFunc, {
        loading: <span className=" break-all">{loadingText}</span>,
        success: <span className=" break-all">{successText}</span>,
        error: <span className=" break-all">{errorText}</span>,
        ...config,
      });
      break;
    }
    default: {
      t.success(<span className="break-all"> {message}</span>, {
        ...config,
      });
    }
  }
};
const useToast = () => {
  const { toasts } = useToasterStore();

  const [toastLimit, setToastLimit] = useState(1);

  useEffect(() => {
    toasts
      .filter((tt) => tt.visible)
      .filter((_, i) => i >= toastLimit)
      .forEach((tt) => t.dismiss(tt.id));
  }, [toasts]);
  const enqueueSnackbar = toastsBar;

  return { enqueueSnackbar, setToastLimit };
};

export default useToast;
