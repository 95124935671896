import { getSyncedDataSources } from "@/axios";
import CasualIcon from "@/components/assets/svgs/CasualIcon";
import FocusedIcon from "@/components/assets/svgs/FocusedIcon";
import OpenAi from "@/components/assets/svgs/OpenAi";
import SonnetIcon from "@/components/assets/svgs/SonnetIcon";
import { toastsBar } from "@/components/shared/snackBar/useToast";
import { noop } from "@/components/utility";
export const AI_MODELS = [
  {
    label: "GPT-4o",
    value: "gpt-4o",
    icon: (props = {}) => <OpenAi fill="#00000" {...props} />,
    isOperational: false,
    isDisabled: false,
  },
  {
    label: "Sonnet 3.5",
    value: "claude-3-5-sonnet-20240620",
    isOperational: false,
    icon: SonnetIcon,
    isDisabled: false,
  },
];
export const CHAT_MODES = [
  {
    label: "Casual",
    value: "casual",
    icon: CasualIcon,
  },
  {
    label: "Focus",
    value: "focus",
    icon: FocusedIcon,
  },
];
export const chatSlice = (set, get) => ({
  chat: [],
  currentDb: null,
  chatMode: CHAT_MODES[0],
  aiModel: AI_MODELS[0],
  currentDbSnapShot: [],
  loadingSnapShot: false,
  setCurrentDb: (val) => set({ currentDb: val }),
  setCurrentDbSnapShot: (snapshot) => set({ currentDbSnapShot: snapshot }),
  setLoadingSnapShot: (val) => set({ loadingSnapShot: val }),
  setChat: (val) => {
    if (typeof val === "function") {
      val({ prev: get().chat, set: (v) => set({ chat: v }) });
    } else {
      set({ chat: val });
    }
  },
  setChatMode: (val) => set({ chatMode: val }),
  setAIModel: (value) => set({ aiModel: value }),
  setSelectedDbSnapShot: async (id) => {
    get().setLoadingSnapShot(true);
    try {
      const res = await getSyncedDataSources(id);
      const { data } = res;
      get().setCurrentDbSnapShot(data.data);
    } catch (error) {
      const { response } = error;
      const { status, data } = response || {};
      if (status) {
        toastsBar(data?.detail, "error");
      } else {
        toastsBar("Something went wrong", "error");
      }
    } finally {
      get().setLoadingSnapShot(false);
    }
  },
  resetChat: () =>
    set({
      chat: [],
      currentDb: null,
      chatMode: CHAT_MODES[0],
      aiModel: AI_MODELS[0],
      currentDbSnapShot: [],
    }),
});

export const editorSlice = (set, get) => ({
  cells: [],
  title: "",
  description: "",
  activeCell: null,
  setTitle: (title) => set({ title }),
  setDescription: (description) => set({ description }),
  setActiveCell: (uuid) => set({ activeCell: uuid }),
  initializeQueryObject: ({ title, description, cells }) =>
    set({ title, description, cells }),

  addCell: (index, cell) => {
    let updatedCells = [];
    set((state) => {
      const newCells = [...state.cells];
      const indx = index !== undefined ? index : newCells.length;
      newCells.splice(indx, 0, cell);
      updatedCells = newCells;
      return { cells: newCells };
    });
    const { title, description } = get();
    return { updatedCells, title, description, newCellId: cell.uuid };
  },

  removeCell: (index) => {
    let updatedCells = [];

    set((state) => {
      const newCells = [...state.cells];
      newCells.splice(index, 1);
      updatedCells = newCells;
      return { cells: newCells };
    });
    const { title, description, activeCell } = get();
    return { updatedCells, title, description, activeCell };
  },

  mutateCell: (cell, updates = {}) => {
    let updatedCells = [];
    set((state) => {
      const newCells = state.cells.map((c) =>
        c.uuid === cell.uuid
          ? { ...c, ...(typeof updates === "function" ? updates(c) : updates) }
          : c
      );
      updatedCells = newCells;

      return { cells: newCells };
    });
    const { title, description, activeCell } = get();
    return { updatedCells, title, description, activeCell };
  },
  deleteAllCells: () => {
    set({ cells: [] });
  },
  resetQueryData: () =>
    set({ cells: [], title: "", description: "", activeCell: null }),
});
