class Node {
  constructor(key, value) {
    this.key = key;
    this.value = value;
    this.next = null;
    this.prev = null;
  }
}

export class LRU {
  #head;
  #tail;
  static instance = null;

  constructor(capacity) {
    if (!LRU.instance) {
      this.capacity = capacity;
      this.#head = null;
      this.#tail = null;
      this.size = 0;
      this.cache = new Map();
      LRU.instance = this;
    } else {
      return LRU.instance;
    }
  }

  get(key) {
    if (!this.cache.has(key)) return -1;

    const node = this.cache.get(key);
    this.#moveToHead(node);
    return this.list();
  }

  put(key, value) {
    if (this.cache.has(key)) {
      const node = this.cache.get(key);
      node.value =
        typeof node.value === "object" ? { ...node.value, ...value } : value;
      this.#moveToHead(node);
    } else {
      const newNode = new Node(key, value);
      this.cache.set(key, newNode);
      this.#addToHead(newNode);

      if (this.size > this.capacity) {
        this.cache.delete(this.#tail.key);
        this.#removeTail();
      }
    }
    return this.list();
  }

  clear() {
    this.#head = null;
    this.#tail = null;
    this.size = 0;
    this.cache = new Map();
  }

  list() {
    const a = [];
    let current = this.#head;
    while (current) {
      a.push({ key: current.key, data: current.value });
      current = current.next;
    }

    return a;
  }

  #addToHead(node) {
    node.next = this.#head;
    node.prev = null;

    if (this.#head !== null) {
      this.#head.prev = node;
    }
    this.#head = node;

    if (this.size === 0) {
      this.#tail = node;
    }
    this.size++;
  }
  // eslint-disable-next-line
  #removeTail() {
    if (this.#tail === null) return;

    if (this.#tail.prev !== null) {
      this.#tail.prev.next = null;
    } else {
      this.#head = null;
    }
    this.#tail = this.#tail.prev;
    this.size--;
  }
  // eslint-disable-next-line
  #moveToHead(node) {
    if (this.#head === node) return;

    // Remove node from its current position
    if (node.prev !== null) {
      node.prev.next = node.next;
    }
    if (node.next !== null) {
      node.next.prev = node.prev;
    }

    if (this.#tail === node) {
      this.#tail = node.prev;
    }

    // Move node to head
    node.next = this.#head;
    node.prev = null;
    if (this.#head !== null) {
      this.#head.prev = node;
    }
    this.#head = node;
  }
}
