import { create } from "zustand";
import slices from "./slices/index.js";
const {
  dataSourceSlice,
  userSlice,
  sseSlice,
  pollingSlice,
  chatSlice,
  editorSlice,
  notificationSlice,
  historySlice,
  recentSlice,
  modeSlice,
} = slices;
export const useAppStore = create((...a) => ({
  ...dataSourceSlice(...a),
  ...userSlice(...a),
  ...sseSlice(...a),
  ...pollingSlice(...a),
  ...chatSlice(...a),
  ...editorSlice(...a),
  ...notificationSlice(...a),
  ...historySlice(...a),
  ...recentSlice(...a),
  ...modeSlice(...a),
}));
