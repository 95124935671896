import { LRU } from "@/components/utility/LRU";

const capacity = 5;
const cache = new LRU(capacity);
export const recentSlice = (set, get) => ({
  limit: 5,
  recents: [],
  cache: cache,
  pushRecent: (key, data) => {
    const list = cache.put(key, data);
    set({ recents: [...list] });
  },
  resetRecents: () => {
    cache.clear();
    set({ recents: [] });
  },
});
