import { getSyncedDataSources } from "@/axios";
import { useAppStore } from "@/components/lib/store";
import useToast from "@/components/shared/snackBar/useToast";
import { noop } from "@/components/utility";
import { useCallback, useState } from "react";
import { BiLogoPostgresql } from "react-icons/bi";
import { PiFileCsv } from "react-icons/pi";
import { SiApacheparquet, SiMicrosoftexcel } from "react-icons/si";
import { useShallow } from "zustand/react/shallow";
const dbTypeIcon = {
  postgresql: (
    <BiLogoPostgresql className="" size={18} fill="#5F5D5D" stroke="#000000" />
  ),
  csv: <PiFileCsv className="" size={18} fill="#5F5D5D" stroke="#000000" />,
  parquet: (
    <SiApacheparquet className="" size={18} fill="#5F5D5D" stroke="#000000" />
  ),
  xlsx: (
    <SiMicrosoftexcel className="" size={18} fill="#5F5D5D" stroke="#000000" />
  ),
  xls: (
    <SiMicrosoftexcel className="" size={18} fill="#5F5D5D" stroke="#000000" />
  ),
};
const useDbSnapshot = () => {
  const {
    currentDb,
    syncedDbOptions,
    setCurrentDb,
    setSyncedDbOptions: setOptions,
    loadingDbOptions,
    setLoadingDbOptions: setLoading,
    loadingSnapShot,
    setSelectedDbSnapShot,
    currentDbSnapShot: snapshot,
  } = useAppStore(
    useShallow((state) => ({
      setSyncedDbOptions: state.setSyncedDbOptions,
      setCurrentDbSnapShot: state.setCurrentDbSnapShot,
      currentDb: state.currentDb,
      setCurrentDb: state.setCurrentDb,
      loadingDbOptions: state.loadingDbOptions,
      setLoadingDbOptions: state.setLoadingDbOptions,
      loadingSnapShot: state.loadingSnapShot,
      setSelectedDbSnapShot: state.setSelectedDbSnapShot,
      syncedDbOptions: state.syncedDbOptions,
      currentDbSnapShot: state.currentDbSnapShot,
    }))
  );
  const [loadingCellSnapShot, setLoadingCellSnapShot] = useState(false);
  const { enqueueSnackbar } = useToast();
  const fetchSyncedDb = useCallback(
    async (isValueRefetch = true, callback = noop) => {
      setLoading(true);
      try {
        const res = await getSyncedDataSources();
        const { data } = res;
        const options = [];
        let selected = {};
        let selectedUUID = null;
        let remote = null;
        for (const [key, value] of Object.entries(data.data)) {
          if (key === "saved_preference") {
            selected = value;
            continue;
          }
          options.push({
            value: value[0],
            label: value[0],
            sourceType: value[1],
            type: dbTypeIcon[value[1]],
            id: key,
            uuid: value[2],
            remote: value[3],
          });
        }
        selected = Object.entries(selected);

        const isEmpty = !selected.length;
        selectedUUID =
          !isEmpty &&
          Object.entries(data.data).find(
            ([key, value]) => key === selected[0][0]
          )?.[1]?.[2];
        remote =
          !isEmpty &&
          Object.entries(data.data).find(
            ([key, value]) => key === selected[0][0]
          )?.[1]?.[3];
        selected = !isEmpty
          ? {
              value: selected[0][1][0],
              label: selected[0][1][0],
              sourceType: selected[0][1][1],
              type: dbTypeIcon[selected[0][1][1]],
              id: selected[0][0],
              uuid: selectedUUID,
              remote: remote,
            }
          : {};
        setOptions(options);
        isEmpty && setCurrentDb(null);
        !isEmpty && isValueRefetch && callback(selected);
      } catch (error) {
        const { response } = error;
        const { status, data } = response || {};
        if (status) {
          enqueueSnackbar(data?.detail, "error");
        } else {
          enqueueSnackbar("Something went wrong", "error");
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );
  const resetCurrentDb = () => setCurrentDb(null);
  const getSnapShot = async (id) => {
    setLoadingCellSnapShot(true);
    const res = await getSyncedDataSources(id);
    setLoadingCellSnapShot(false);
    return res;
  };
  return {
    snapshot,
    fetchSyncedDb,
    loadingDbOptions,
    loadingSnapShot,
    loadingCellSnapShot,
    setSelectedDbSnapShot: (value) => {
      setSelectedDbSnapShot(value.id);
    },
    currentDb,
    setCurrentDb,
    resetCurrentDb,
    syncedDbOptions,
    getSnapShot,
  };
};

export default useDbSnapshot;
