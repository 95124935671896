import { useCallback, useEffect, useState } from "react";

const initialize = (key, initialValue) => {
  try {
    const item = localStorage.getItem(key);
    if (item && item !== "undefined") {
      return JSON.parse(item);
    }

    localStorage.setItem(key, JSON.stringify(initialValue));
    return initialValue;
  } catch {
    return initialValue;
  }
};

const useLocalStorage = (key, initialValue) => {
  const [state, setState] = useState(null);

  useEffect(() => {
    setState(initialize(key, initialValue));
  }, []);

  const setValue = useCallback(
    (value) => {
      setState(value);
      localStorage.setItem(key, JSON.stringify(value));
    },
    [key, setState]
  );

  const remove = useCallback(() => {
    localStorage.removeItem(key);
  }, [key]);

  return [state, setValue, remove];
};

export default useLocalStorage;
