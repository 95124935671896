import Cookies from "js-cookie";
const TOKEN_NAME = "fl_tk";
const USER = "fl_usr";
export const setItem = (key, value, config = {}) =>
  Cookies.set(key, value, config);
export const getItem = (key) => Cookies.get(key);
export const deleteItem = (key) => Cookies.remove(key);

export const setToken = (token) => setItem(TOKEN_NAME, token, { expires: 1 });
export const getToken = () => getItem(TOKEN_NAME);
export const removeToken = () => deleteItem(TOKEN_NAME);

export const setUser = (user) => setItem(USER, user, { expires: 1 });
export const getUser = () => getItem(USER);
export const removeUser = () => deleteItem(USER);
